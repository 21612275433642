import React from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/core/components/ui/accordion';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/core/components/ui/popover';
import { Separator } from '@/core/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/core/components/ui/tooltip';
import { cn } from '@/core/libs/utils';

import { Icon } from '../component-library/Icon/Icon';
import { IconType } from '../component-library/Icon/IconType';
import { useLayoutContext } from './Layout';

type NavMenuProps = {
  title: string;
  icon: IconType;
  children: React.ReactNode;
};

export const NavMenu = React.forwardRef<HTMLButtonElement, NavMenuProps>(
  ({ title, icon, children }, ref) => {
    const { expanded } = useLayoutContext();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    return expanded ? (
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Accordion
              type="single"
              collapsible
              className="w-full"
              onValueChange={(value) => {
                if (value) {
                  setIsOpen(true);
                } else {
                  setIsOpen(false);
                }
              }}
            >
              <AccordionItem value={title} className="mb-0 bg-opacity-0">
                <AccordionTrigger
                  className={cn(
                    'relative text-neutral-600 font-normal transition-colors w-full px-4 bg-white flex items-center gap-4 no-underline py-4 justify-start text-base h-12 hover:bg-neutral-100 rounded-xl hover:no-underline hover:text-neutral-600 focus-visible:no-underline focus-visible:text-neutral-600',
                  )}
                  ref={ref}
                >
                  <Icon
                    type={icon}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  <span
                    className={cn(
                      'overflow-hidden transition-all text-left',
                      expanded ? 'w-full' : 'w-0 hidden',
                    )}
                  >
                    {title}
                  </span>
                </AccordionTrigger>
                <AccordionContent className="bg-white">
                  {children}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </TooltipTrigger>
          {!isOpen && (
            <TooltipContent side="right" className="py-1">
              {title}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    ) : (
      <Popover>
        <PopoverTrigger
          className={cn(
            'relative text-neutral-600 transition-colors w-full px-4 bg-white flex items-center gap-4 no-underline py-4 justify-start text-base h-12 hover:bg-neutral-100 rounded-xl hover:no-underline hover:text-neutral-600 focus-visible:no-underline focus-visible:text-neutral-600',
          )}
          ref={ref}
        >
          <Icon
            type={icon}
            className="w-6 h-6 text-xl flex items-center justify-center"
          />
          <span
            className={cn(
              'overflow-hidden transition-all text-left',
              expanded ? 'w-52' : 'w-0 hidden',
            )}
          >
            {title}
          </span>
        </PopoverTrigger>
        <PopoverContent side="right">
          <div className="text-neutral-600">{title}</div>
          <Separator className="my-2" />
          {children}
        </PopoverContent>
      </Popover>
    );
  },
);
NavMenu.displayName = 'NavMenu';
