import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useIntl } from 'react-intl';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { IconType } from '@/core/components/component-library/Icon/IconType';
import { Button } from '@/core/components/ui/button';
import { cn } from '@/core/libs/utils';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-secondary-900/50 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    shouldCloseOnOverlayClick?: boolean;
    openSoftClose?: boolean;
    softCloseOptions?: {
      onCancel(): void;
      onCloseConfirm(): void;
      text: string;
    };
    classNameClose?: string;
  }
>(
  (
    {
      className,
      children,
      shouldCloseOnOverlayClick = true,
      softCloseOptions = {},
      openSoftClose = false,
      classNameClose,
      ...props
    },
    ref,
  ) => {
    const { $t } = useIntl();

    return (
      <DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content
          ref={ref}
          className={cn(
            'max-h-screen overflow-x-hidden overflow-y-auto md:overflow-visible fixed left-[50%] top-[50%] z-50 grid w-full max-w-xl translate-x-[-50%] translate-y-[-50%] gap-6 bg-white p-6 md:p-10 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl md:w-full',
            className,
          )}
          onPointerDownOutside={(e) => {
            if (shouldCloseOnOverlayClick) return;
            e.preventDefault();
          }}
          {...props}
        >
          {openSoftClose && softCloseOptions && (
            <>
              <DialogHeader>
                <DialogTitle>
                  {$t({ id: 'General.leaveWithoutSaving' })}
                </DialogTitle>
                <DialogDescription>{softCloseOptions.text}</DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button
                  onClick={softCloseOptions.onCancel}
                  type="button"
                  appearance="filled"
                  color="neutral"
                >
                  {$t({ id: 'General.noCancel' })}
                </Button>
                <Button
                  type="submit"
                  appearance="filled"
                  color="danger"
                  onClick={softCloseOptions.onCloseConfirm}
                >
                  {$t({ id: 'General.leaveWithoutSavingButton' })}
                </Button>
              </DialogFooter>
            </>
          )}
          {!openSoftClose && children}
          <DialogPrimitive.Close
            aria-label={$t({ id: 'General.close' })}
            className={cn(
              'absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500',
              classNameClose,
            )}
          >
            <Icon type={IconType.X_CROSS} className="h-4 w-4" />
            <span className="sr-only">{$t({ id: 'General.close' })}</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPortal>
    );
  },
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-3 text-center sm:text-left',
      className,
    )}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse gap-y-3 sm:gap-0 sm:flex-row sm:justify-end sm:space-x-4',
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-xl font-medium', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-base text-neutral-700', className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
