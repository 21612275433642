/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  ForwardedRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import classNames from 'classnames';

import { IconType } from './IconType';

import './Icon.css';

export type Rotation = 90 | 180 | 270;
export type Flip = 'x' | 'y';

export interface IconProps {
  type: IconType;
  className?: string;
  rotate?: Rotation;
  flip?: Flip;
  spin?: boolean;
  rsuite?: boolean;
}

function Icon(
  { type, className, rotate, flip, spin, rsuite, ...otherProps }: IconProps,
  ref: ForwardedRef<HTMLElement>,
): JSX.Element {
  return (
    <i
      ref={ref}
      data-testid="icon"
      className={classNames('of-icons', type, className, {
        [`of-icons-rotate-${rotate}`]: rotate,
        [`of-icons-flip-${flip}`]: flip,
        'of-icons-is-spinning': spin,
        'rs-icon': rsuite,
      })}
      aria-hidden="true"
      {...otherProps}
    />
  );
}

// We need all this because if not we cannot use the RSuite tooltip and pass the Icon type prop...
type ForwardedIconType = ForwardRefExoticComponent<
  PropsWithoutRef<IconProps> & RefAttributes<HTMLElement>
> & { type: typeof IconType };

const ForwardedIcon: ForwardedIconType = React.forwardRef(
  Icon,
) as ForwardedIconType;

ForwardedIcon.type = IconType;

export { ForwardedIcon as Icon };
