import { useCallback, useState } from 'react';
import { FileType } from 'rsuite/esm/Uploader';

import { errorHandler } from '@/core/libs/error-handler';
import { renameFile } from '@/core/libs/file-utils';
import { FilesService } from '@/generated/api';

const METHODS = {
  public: FilesService.filesControllerGenerateTempS3UploadCredentials,
  'sample-parts':
    FilesService.filesControllerGenerateCompanyS3UploadCredentials,
};

export interface S3UploadSignature {
  name: string;
  url: string;
}

export interface UseS3Uploader {
  upload(file: FileType): Promise<S3UploadSignature>;
  loading: boolean;
}

export default function useS3Uploader({
  uploadType,
}: {
  uploadType: 'public' | 'sample-parts';
}): UseS3Uploader {
  const [loading, setLoading] = useState(false);

  const uploadFile = useCallback(
    async (file: FileType): Promise<S3UploadSignature> => {
      if (!(file instanceof File) && !(file?.blobFile instanceof File))
        throw Error('Wrong file passed');
      setLoading(true);
      try {
        const generateUploadCredentials = await METHODS[uploadType]();
        const renamedFile =
          uploadType === 'public'
            ? file
            : (renameFile(file as File, `${Date.now()}_${file.name}`) as
                | FileType
                | File);
        const newFile =
          renamedFile instanceof File
            ? renamedFile
            : (renamedFile?.blobFile as File);
        const formData = generateUploadCredentials.fields.reduce<FormData>(
          (form: FormData, { key, value }) => {
            form.append(key, value);
            return form;
          },
          new FormData(),
        );
        formData.append(
          'Content-Type',
          file?.blobFile?.type || 'application/octet-stream',
        );
        formData.append('file', newFile);

        await window.fetch(generateUploadCredentials.uploadUrl, {
          method: 'POST',
          body: formData,
          credentials: 'omit',
        });

        return {
          name: newFile.name,
          url: `${generateUploadCredentials.publicUrl}/${encodeURIComponent(
            newFile.name,
          )}`,
        };
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [uploadType],
  );

  return { upload: uploadFile, loading };
}
