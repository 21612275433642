import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/core/libs/utils';

const inputVariants = cva(
  'flex w-full rounded-none border-b transition-colors file:border-0 file:bg-transparent file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        md: 'h-12 p-3 text-base file:text-base',
        sm: 'h-[34px] py-[7px] px-3 text-sm file:text-sm',
      },
      mode: {
        light:
          'border-neutral-400 bg-neutral-50 placeholder:text-neutral-500 hover:border-b-primary-700 focus-visible:border-b-primary-700 text-black',
        dark: 'border-b-secondary-800 bg-secondary-800 placeholder:text-neutral-400 hover:border-b-primary-500 focus-visible:border-b-primary-500 text-secondary-50',
      },
    },
    defaultVariants: {
      size: 'md',
      mode: 'light',
    },
  },
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, mode, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ size, mode, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input, inputVariants };
