import { cva, type VariantProps } from 'class-variance-authority';
import { useIntl } from 'react-intl';

import { cn } from '@/core/libs/utils';

import { Icon } from './Icon';

const loaderVariants = cva('animate-spin', {
  variants: {
    size: {
      xs: '!text-md font-bold',
      sm: '!text-2xl font-bold',
      md: '!text-4xl font-bold',
      lg: '!text-6xl font-extrabold',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type LoaderProps = VariantProps<typeof loaderVariants> & {
  className?: string;
  showText?: boolean;
};

export function Loader({
  size,
  className,
  showText = false,
}: LoaderProps): JSX.Element {
  const { $t } = useIntl();

  return (
    <div className="text-secondary-600 flex flex-row items-center">
      <Icon
        type={Icon.type.CIRCLE}
        className={cn(loaderVariants({ size, className }))}
      />
      {showText && <div className="ml-2">{$t({ id: 'General.loading' })}</div>}
    </div>
  );
}
