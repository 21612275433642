/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Subscription = {
  id: number;
  created_at: string;
  updated_at: string;
  stripeSubscriptionId: string;
  productId: string;
  priceId: string;
  price: number;
  currency: string;
  expiresAt: string;
  state: Subscription.state;
  cancelAt: string | null;
  trialEnd: string | null;
  billingCycle: string;
  /**
   * Populated only programatically when needed
   */
  upcomingAmount: number | null;
  /**
   * Populated only programatically when needed
   */
  nextBillingDate: string | null;
  /**
   * Populated when partfox subscription is explicitly enabled for the first time
   */
  startedAt: string | null;
  distributorCode: string | null;
};

export namespace Subscription {
  export enum state {
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    UNPAID = 'unpaid',
    CANCELED = 'canceled',
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
  }
}
