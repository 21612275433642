import { Separator } from '@/core/components/ui/separator';

import { useLayoutContext } from './Layout';

type NavTitleProps = {
  name: string;
};

export function NavTitle({ name }: NavTitleProps): JSX.Element {
  const { expanded } = useLayoutContext();
  if (!expanded)
    return (
      <div className="px-4">
        <Separator className="my-5" />
      </div>
    );
  return (
    <div className="grid px-4 py-3 text-neutral-500 text-sm grid-cols-[max-content_auto] items-center gap-4">
      {expanded && <div>{name}</div>}
      <Separator />
    </div>
  );
}
