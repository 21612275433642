/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';

import { SideNav } from '@/core/components/layout/sidenav';
import { TopNav } from '@/core/components/layout/topnav';
import { Toaster } from '@/core/components/ui/toaster';
import { Breakpoints, useBreakpoint } from '@/core/context/BreakpointContext';

import './Layout.css';

export interface LayoutProps {
  children: ReactNode;
  hideNavigationBar?: boolean;
  className?: string;
}

const LayoutContext = createContext({
  hideNavigationBar: () => {},
  showNavigationBar: () => {},
  expanded: false,
});

type LayoutContextProps = {
  hideNavigationBar: () => void;
  showNavigationBar: () => void;
  expanded: boolean;
};

export function useLayoutContext(): LayoutContextProps {
  return useContext(LayoutContext);
}

export default function Layout({
  children,
  className,
}: LayoutProps): JSX.Element {
  const { breakpoint } = useBreakpoint();
  const [navbarExpanded, setNavbarExpanded] = useState<boolean>(false);
  const [navigationVisible, setNavigationVisible] = useState<boolean>(true);

  return (
    <div className={classNames('layout', className)}>
      <LayoutContext.Provider
        value={{
          hideNavigationBar: () => setNavigationVisible(false),
          showNavigationBar: () => setNavigationVisible(true),
          expanded: navbarExpanded,
        }}
      >
        {navigationVisible && (
          <>
            <SideNav expanded={navbarExpanded} onExpanded={setNavbarExpanded} />
            <TopNav
              expanded={navbarExpanded && breakpoint <= Breakpoints.LG}
              onExpanded={setNavbarExpanded}
            />
          </>
        )}
        <main className="layout__main-content">{children}</main>
        <Toaster />
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/438ce3a8-c0a4-4b00-bc6b-302716144c1b/cd.js"
          type="text/javascript"
          async
        />
      </LayoutContext.Provider>
    </div>
  );
}
