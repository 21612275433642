/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Company } from './Company';

export type User = {
  id: number;
  created_at: string;
  updated_at: string;
  /**
   * The ID of the Supabase user
   */
  externalId: string;
  firstName: string;
  lastName: string;
  /**
   * The email address of the user, also used for login
   */
  email: string;
  /**
   * The role which determines the permissions of the user
   */
  role: User.role;
  /**
   * If the user is waiting for an enrollment from a peer
   */
  waitingForEnrollment: boolean;
  phone: string | null;
  /**
   * The user locale (de, en, it, es or zh)
   */
  locale: string;
  /**
   * Whether the user has accepted the GTCs or not. If this is false, the use won't be able to use the protected routes
   */
  termsAccepted: boolean;
  company: Company;
  /**
   * How the user will use the platform (buyer, manufacturer or both)
   */
  type: User.type;
  lastLogin: string | null;
};

export namespace User {
  /**
   * The role which determines the permissions of the user
   */
  export enum role {
    '_0' = 0,
    '_1' = 1,
  }

  /**
   * How the user will use the platform (buyer, manufacturer or both)
   */
  export enum type {
    MANUFACTURER = 'manufacturer',
    BUYER = 'buyer',
    BOTH = 'both',
  }
}
