import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

import { DropZone } from '@/core/components/component-library/drop-zone';
import useS3Uploader from '@/core/hooks/useS3Uploader';
import { errorHandler } from '@/core/libs/error-handler';
import { normalizeFile } from '@/core/libs/file-utils';
import { toast } from '@/core/libs/toast';
import { CompaniesService, SamplePartDto } from '@/generated/api';

export enum AcceptedFiles {
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
}

export enum UploadingStatus {
  STANDBY = 'standby',
  UPLOADING = 'uploading',
  ANALYZING = 'analyzing ',
}

export interface ISamplePartsContext {
  isLoading: boolean;
  userSampleParts: SamplePartDto[];
  createSamplePart: (samplePart: SamplePartDto) => Promise<void>;
  editSamplePart: (samplePart: SamplePartDto) => Promise<void>;
  deleteSamplePart: (samplePart: SamplePartDto) => Promise<void>;
  deleteBulkSampleParts: (sampleParts: SamplePartDto[]) => Promise<void>;

  uploadStatus: UploadingStatus;
  sizeLimitMB: number;
  uploadToAWSAndSaveSampleParts(file: FileList): Promise<void>;
}

export const SamplePartsContext = createContext<ISamplePartsContext>({
  isLoading: false,
  userSampleParts: [],
  createSamplePart: async () => {
    errorHandler.capture(
      'createSamplePart() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  editSamplePart: async () => {
    errorHandler.capture(
      'editSamplePart() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  deleteSamplePart: async () => {
    errorHandler.capture(
      'deleteSamplePart() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  deleteBulkSampleParts: async () => {
    errorHandler.capture(
      'deleteBulkSampleParts() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },

  uploadStatus: UploadingStatus.STANDBY,
  sizeLimitMB: 0,
  uploadToAWSAndSaveSampleParts: () => {
    errorHandler.capture(
      'uploadToAWSAndSaveSampleParts() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
});

interface FileDropSamplePartsProviderProps {
  children?: React.ReactNode;
}

export function SamplePartsProvider({
  children,
}: FileDropSamplePartsProviderProps): JSX.Element {
  const { $t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [userSampleParts, setUserSampleParts] = useState<SamplePartDto[]>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadingStatus>(
    UploadingStatus.STANDBY,
  );
  const sizeLimitMB = 50;
  const { upload } = useS3Uploader({ uploadType: 'sample-parts' });

  const getSampleParts = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const data =
        await CompaniesService.samplePartsControllerFindSampleParts();
      setUserSampleParts([...data]);
    } catch (error) {
      errorHandler.capture(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createSamplePart = useCallback(
    async (samplePart: SamplePartDto): Promise<void> => {
      try {
        setIsLoading(true);
        await CompaniesService.samplePartsControllerUpsertSamplePart(
          samplePart,
        );
        toast({
          appearance: 'success',
          message: $t({ id: 'SampleParts.notify.successCreate' }),
        });
        await getSampleParts();
      } catch (error) {
        errorHandler.capture(error, {
          userErrorMessage: $t({ id: 'SampleParts.notify.errorCreate' }),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [$t],
  );

  const editSamplePart = useCallback(
    async (samplePart: SamplePartDto): Promise<void> => {
      try {
        setIsLoading(true);
        await CompaniesService.samplePartsControllerUpsertSamplePart(
          samplePart,
        );
        toast({
          appearance: 'success',
          message: $t({ id: 'SampleParts.notify.successEdit' }),
        });
        await getSampleParts();
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setIsLoading(false);
      }
    },
    [$t],
  );

  const deleteSamplePart = useCallback(
    async (samplePart: SamplePartDto): Promise<void> => {
      try {
        setIsLoading(true);
        await CompaniesService.samplePartsControllerDeleteSamplePart(
          samplePart.filename,
        );
        toast({
          appearance: 'success',
          message: $t({ id: 'SampleParts.notify.successDelete' }),
        });
        await getSampleParts();
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setIsLoading(false);
      }
    },
    [$t],
  );

  const deleteBulkSampleParts = useCallback(
    async (sampleParts: SamplePartDto[]): Promise<void> => {
      try {
        setIsLoading(true);
        await CompaniesService.samplePartsControllerDeleteSamplePartsBulk({
          filenames: sampleParts.map((samplePart) => samplePart.filename),
        });
        toast({
          appearance: 'success',
          message: $t({ id: 'SampleParts.notify.successDelete' }),
        });
        await getSampleParts();
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setIsLoading(false);
      }
    },
    [$t],
  );

  const uploadToAWSAndSaveSampleParts = useCallback(
    async (files: FileList): Promise<void> => {
      setUploadStatus(UploadingStatus.UPLOADING);
      try {
        for (const file of files) {
          const fileUploaded = await upload(normalizeFile(file));
          const samplePart: SamplePartDto = {
            id: null,
            filename: fileUploaded.name,
            url: fileUploaded.url,
            technologies: [],
            materials: [],
            shapes: [],
            sizes: [],
          };
          await createSamplePart(samplePart);
        }
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setUploadStatus(UploadingStatus.STANDBY);
      }
    },
    [createSamplePart, upload],
  );

  useEffect(() => {
    getSampleParts();
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      userSampleParts,
      createSamplePart,
      editSamplePart,
      deleteSamplePart,
      deleteBulkSampleParts,
      uploadStatus,
      sizeLimitMB,
      uploadToAWSAndSaveSampleParts,
    }),
    [
      isLoading,
      userSampleParts,
      createSamplePart,
      editSamplePart,
      deleteSamplePart,
      deleteBulkSampleParts,
      uploadStatus,
      uploadToAWSAndSaveSampleParts,
    ],
  );

  return (
    <SamplePartsContext.Provider value={value}>
      <DropZone
        onDrop={(files): void => {
          const filesToUpload = files.filter(
            (file) => file,
          ) as unknown as FileList;
          uploadToAWSAndSaveSampleParts(filesToUpload);
        }}
        loading={uploadStatus !== UploadingStatus.STANDBY}
        sizeLimitMB={sizeLimitMB}
        allowedExtensions={Object.values(AcceptedFiles)}
      >
        {children || <Outlet />}
      </DropZone>
    </SamplePartsContext.Provider>
  );
}

SamplePartsContext.displayName = 'FileDropSamplePartsContext';

export function useSamplePartsContext(): ISamplePartsContext {
  return useContext(SamplePartsContext);
}
