import { useQuery } from '@tanstack/react-query';

import { ManufacturerListsService } from '@/generated/api';

export function useManufacturerLists() {
  return useQuery({
    queryKey: ['manufacturerLists'],
    queryFn:
      ManufacturerListsService.manufacturerListsControllerGetManufacturerLists,
  });
}

export function useManufacturerList(listId: number) {
  return useQuery({
    queryKey: ['manufacturerList', { listId }],
    queryFn: () =>
      ManufacturerListsService.manufacturerListsControllerGetManufacturersListById(
        listId,
      ),
    enabled: !!listId,
  });
}
