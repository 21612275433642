import {
  certifications,
  companysizes,
  countries,
  machineries,
  materials,
  technologies,
} from '@orderfoxag/reference-data';

import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import partTypesData from '@/modules/matchmaking/types/searchables/partTypes.json';
import { regions } from '@/modules/matchmaking/types/searchables/regions-overrides';
import { SerializedSearchableConfiguration } from '@/modules/matchmaking/types/SerializedSearchableConfiguration';
import { SyntheticSearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SyntheticSearchableConfigurationFactory';
import { ReferenceDataMapper } from '@/modules/matchmaking/utils/ReferenceDataMapper';

export class SearchableConfigurationFactory {
  public static getAllPossibleSuggestions(): SearchableConfiguration[] {
    const result: SearchableConfiguration[] = [];
    for (const categoryEnumKey in CategoryEnum) {
      result.concat(
        SearchableConfigurationFactory.getSearchableConfigurations(
          categoryEnumKey as CategoryEnum,
        ),
      );
    }
    return result;
  }

  /**
   * There are multiple kind of searchable:
   * - **Synthetics** are generated at runtime.
   * - **Reference-data** are pulled from the reference-data package and then enriched with weights stored in this project.
   * - **local-data** are stored in json files inside this project including their weights.
   *
   * @param categoryEnumKey
   */
  static getSearchableConfigurations(
    categoryEnumKey: CategoryEnum,
  ): SearchableConfiguration[] {
    switch (categoryEnumKey) {
      case CategoryEnum.category:
        return SyntheticSearchableConfigurationFactory.getSyntheticCategories();
      case CategoryEnum.certifications:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          certifications,
        );
      case CategoryEnum.materials:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          [
            ...materials.filter(({ id }) =>
              [
                'm1', // Acrylic (PMMA)
                'm10', // Composites
                'm100', // PVC
                'm101', // POM
                'm105', // Nylon 6
                'm106', // Nylon 6.6
                'm11', // Duroplast
                'm13', // Stainless steel
                'm15', // Case-hardened / heat-treated steel
                'm16', // Elastomers
                'm17', // Spring steel
                'm19', // Glass fibre reinforced plastic
                'm20', // Glass / minerals
                'm200', // PE
                'm201', // PS
                'm202', // Gold
                'm203', // Silver
                'm204', // Paladium
                'm205', // Platinum
                'm21', // Glass ceramic / lithium disilicate
                'm23', // Precious metals
                'm25', // Grey iron
                'm26', // Cast iron
                'm27', // Carbides
                'm28', // High-performance plastics
                'm29', // Solid wood
                'm30', // Cold forged steel
                'm32', // Ceramic
                'm33', // Cobalt-chrome
                'm35', // Copper and copper alloys
                'm39', // Magnesium
                'm3D001', // ABS
                'm3D008', // PC
                'm3D015a', // PA
                'm3D017a', // PP
                'm3D027a', // PHA
                'm3D028', // PEEK
                'm3D029a', // HIPS
                'm3D030', // PLA
                'm3D033a', // PETG
                'm3D034', // TPU
                'm3D035a', // TPE
                'm3D036', // PVA
                'm40', // Brass
                'm43', // Molybdenum
                'm44', // Natural fibre reinforced plastic
                'm46', // Nickel alloys
                'm47', // Optical glass
                'm5', // Aluminium
                'm50', // Quartz glass
                'm56', // Silicon carbide
                'm57', // Silicon nitride
                'm61', // Cast steel
                'm63', // Thermoplastic
                'm64', // Titanium
                'm66', // Tool steel
                'm67', // Tungsten
                'm7', // Machining steel
                'm70', // Zinc and zinc alloys
                'm77', // Plywood
                'm8', // Construction steel
                'm9', // Carbon fibre reinforced plastic
                'm94', // Composite wood
                'm97', // Form solid wood
                'm98', // Watering solid wood
                'mt01', // Cast metals
                'mt03', // Compounds
                'mt04', // Elastic plastics
                'mt05', // Light metals
                'mt06', // Natural materials
                'mt07', // Nonferrous metals
                'mt10', // Plastics
                'mt13', // Steel
                'mt15', // Wood
                'mt16', // High-performance alloys'
              ].includes(id),
            ),
          ],
        );
      case CategoryEnum.technologies:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          [
            ...technologies.filter(({ id }) =>
              [
                'pt301', // Powder coating
                'pt302', // Liquid painting
                'pt303', // Anodizing
                'pt304', // Nitrogenizing
                'pt305', // Burnishing
                'pt306', // Galvanising
                'pt308', // Nickel plating
                'pt309', // Chrome plating
                'pt310', // Phosphatisation
                'pt403', // Blasting
                'pt410', // Electropolishing
                'pt411', // Polishing
                'pt6', // Cutting
                'pt601', // Flame cutting
                'pt701', // Forging
                'pt703', // Casting
                'pt704', // Plastic injection molding
                'pt706', // Die casting
                'pt801', // Hardening
                'pt802', // Annealing
                'pt803', // Tempering
                'pt9', // Heat treatment
                't1', // Turning
                't12', // Flat grinding
                't12a', // Turning 2 axes
                't13', // Round grinding
                't13pa', // Turning 3+ axes
                't14', // Laser cutting
                't15', // Plasma cutting
                't16', // Stamping
                't17', // Shearing
                't19', // Chamfering / bending
                't2', // Milling (we keep this one as for now file analyzer returns this)
                't20', // Cold forming
                't21', // Deep drawing
                't23', // Rounding / bending
                't24', // Rolling / Rounding
                't25', // Welding
                't26', // Laser welding
                't32', // 3-axis-CNC-mill
                't33', // 5-axis-CNC-mill
                't37', // Water jet cutting
                't3D001', // Additive Manufacturing / 3D printing
                't42', // Coating
                't47', // Deep drilling
                't48', // EDM (sinking)
                't49', // EDM (wire)
                't50', // MIG/MAG Welding
                't51', // TIG/WIG Welding
                't52', // Spot Welding
                't53', // Plasma Welding
                't54', // Sand Casting
                't55', // Investment Casting
                't56', // Selective Laser Sintering (SLS)
                't57', // Direct Metal Laser Sintering (DMLS)
                't58', // Fused Deposition Modeling (FDM)
                't59', // Stereolithography (SLA)
                't6', // Grinding
                't60', // Selective Laser Melting (SLM)
                't61', // Electron Beam Melting (EBM)
                't7', // Eroding (EDM)
              ].includes(id),
            ),
          ],
        );
      case CategoryEnum.countries:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          countries,
        );
      case CategoryEnum.machinery:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          machineries,
        );
      case CategoryEnum.regions:
        return ReferenceDataMapper.mapRegionTypeToSearchableConfiguration(
          categoryEnumKey,
          regions,
        );
      case CategoryEnum.companySize:
        return ReferenceDataMapper.mapCompanySizeTypeToSearchableRange(
          categoryEnumKey,
          companysizes,
        );
      case CategoryEnum.height:
      case CategoryEnum.length:
      case CategoryEnum.width:
      case CategoryEnum.diameter:
        return SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
          categoryEnumKey,
        );
      case CategoryEnum.partType:
        return SearchableConfigurationFactory.deserializeSearchableConfiguration(
          partTypesData,
        );
      default:
        return [];
    }
  }

  protected static deserializeSearchableConfiguration(
    serializedJsonData: SerializedSearchableConfiguration[],
  ): SearchableConfiguration[] {
    return serializedJsonData.map(
      (data) =>
        new SearchableConfigurationImpl(
          data.category as CategoryEnum,
          data.id,
          [],
          data.searchWeight,
          [],
          data.translationKey,
          data.searchWeight,
          '',
        ),
    );
  }
}

export const searchableTechnologies: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.technologies,
  );

export const searchableMaterials: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.materials,
  );

export const searchableCountries: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.countries,
  );

export const searchableCertificates: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.certifications,
  );
