import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { useUserTracking } from '@/core/hooks/useUserTracking';
import { errorHandler } from '@/core/libs/error-handler';
import { getManufacturersByUrl } from '@/modules/matchmaking/services/get-manufacturers-by-url';
import {
  searchableCertificates,
  searchableCountries,
  searchableMaterials,
  searchableTechnologies,
} from '@/modules/matchmaking/utils/factories/SearchableConfigurationFactory';
import {
  FeedElementDto,
  PaginatedResult,
  ProjectsService,
  SearchMachineDto,
} from '@/generated/api';

import {
  FEED_REDUCER_ACTION_TYPE,
  feedFiltersReducer,
  feedFiltersReducerInitialState,
  FeedFiltersReducerType,
} from '../reducer/feed-filters-reducer';

type ProjectPaginationResponse<T> = PaginatedResult & {
  items: Array<T>;
};

export type FilterRange = {
  countryCodes: string[];
  minWorkpieces: number;
  maxWorkpieces: number;
  minLotSizes: number;
  maxLotSizes: number;
};

export const FilterType = {
  machines: 'machines',
  technologies: 'technologies',
  materials: 'materials',
  workpieces: 'workpieces',
  lotSizes: 'lotSizes',
  certifications: 'certifications',
  countryCodes: 'countryCodes',
  incoterms: 'incoterms',
} as const;
export type FilterType = (typeof FilterType)[keyof typeof FilterType];

export type FeedContextType = {
  isLoading: boolean;
  viewMode: 'table' | 'cards';
  setViewMode: (newViewMode: 'table' | 'cards') => void;
  filterRanges: FilterRange;
  projects: FeedElementDto[];
  clearAllFilters: () => void;
  clearFiltersDisabled: boolean;
  applyOverrideWithAI: () => void;
  filters: FeedFiltersReducerType;
  dispatchFilters: React.Dispatch<FEED_REDUCER_ACTION_TYPE>;
  filtersCount: number;
  filtersToSort: FilterType[];
  setFiltersToSort: React.Dispatch<React.SetStateAction<FilterType[]>>;
  showAddMachineHint: boolean;
  setShowAddMachineHint: React.Dispatch<React.SetStateAction<boolean>>;
  setProjects: React.Dispatch<React.SetStateAction<FeedElementDto[]>>;
  selectedProjectIdContact: number;
  setSelectedProjectIdContact: React.Dispatch<React.SetStateAction<number>>;
  selectedProjectNdaModal?: FeedElementDto;
  setSelectedProjectNdaModal: React.Dispatch<
    React.SetStateAction<FeedElementDto | undefined>
  >;
};

export const FeedContext = React.createContext<FeedContextType>({
  isLoading: false,
  viewMode:
    localStorage.getItem('feed:viewMode') === 'table' ? 'table' : 'cards',
  setViewMode: () => {},
  filterRanges: {
    countryCodes: [],
    minWorkpieces: 1,
    maxWorkpieces: 1,
    minLotSizes: 1,
    maxLotSizes: 1,
  },
  projects: [],
  clearAllFilters: () => {},
  clearFiltersDisabled: true,
  applyOverrideWithAI: () => {},
  filters: feedFiltersReducerInitialState,
  dispatchFilters: () => {},
  filtersCount: 0,
  filtersToSort: [],
  setFiltersToSort: () => {},
  showAddMachineHint: false,
  setShowAddMachineHint: () => {},
  setProjects: () => {},
  selectedProjectIdContact: 0,
  setSelectedProjectIdContact: () => {},
  setSelectedProjectNdaModal: () => {},
});

export function FeedProvider({ children }: { children?: React.ReactNode }) {
  const {
    isLoggedIn,
    isLoading: isLoadingUserInfo,
    currentUser,
  } = useAuthenticationContext();
  const { trackUserEvent, TrackerEvents } = useUserTracking();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [viewMode, setViewMode] = React.useState<'table' | 'cards'>(
    localStorage.getItem('feed:viewMode') === 'table' ? 'table' : 'cards',
  );

  const [filtersToSort, setFiltersToSort] = React.useState<FilterType[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] =
    React.useState<boolean>(false);
  const [filterRanges, setFilterRanges] = React.useState<FilterRange>({
    countryCodes: [],
    minWorkpieces: 1,
    maxWorkpieces: 1,
    minLotSizes: 1,
    maxLotSizes: 1,
  });
  const [projects, setProjects] = React.useState<FeedElementDto[]>([]);
  const [wasUserLoggedInAtLastFetch, setWasUserLoggedInAtLastFetch] =
    React.useState<boolean>(isLoggedIn);
  const [showAddMachineHint, setShowAddMachineHint] =
    React.useState<boolean>(false);

  const [filters, dispatchFilters] = React.useReducer(
    feedFiltersReducer,
    feedFiltersReducerInitialState,
  );

  /** Used to show Contact Detail modal */
  const [selectedProjectIdContact, setSelectedProjectIdContact] =
    React.useState<number>(0);
  /** Used to show sign NDA modal */
  const [selectedProjectNdaModal, setSelectedProjectNdaModal] =
    React.useState<FeedElementDto>();

  const {
    showExpired,
    sort,
    technologyIds,
    certificationsIds,
    incoterms,
    countryCodes,
    workpieces,
    lotSizes,
    materialIds,
    machines,
    pagination,
  } = filters;

  const getFeedProjects = React.useCallback(
    async (pageNumber: number, pageSize: number) => {
      try {
        if (isLoading || isLoadingFilters) return;
        setIsLoading(true);
        const fetchPaginatedSearch = isLoggedIn
          ? ProjectsService.projectsControllerPaginatedSearch
          : ProjectsService.projectsControllerPublicPaginatedSearch;
        const projectsData = (await fetchPaginatedSearch(
          pageNumber,
          pageSize,
          sort === 'newest' ? showExpired : false,
          sort,
          workpieces[0] !== filterRanges.minWorkpieces
            ? workpieces[0]
            : undefined,
          workpieces[1] !== filterRanges.maxWorkpieces
            ? workpieces[1]
            : undefined,
          lotSizes[0] !== filterRanges.minLotSizes ? lotSizes[0] : undefined,
          lotSizes[1] !== filterRanges.maxLotSizes ? lotSizes[1] : undefined,
          technologyIds,
          materialIds,
          certificationsIds,
          incoterms,
          countryCodes,
          machines.length > 0
            ? (JSON.stringify(machines) as unknown as SearchMachineDto[])
            : undefined,
        )) as ProjectPaginationResponse<FeedElementDto>;
        const query = {
          viewMode,
          sort,
          minWorkpieces: workpieces[0],
          maxWorkpieces: workpieces[1],
          minLotSizes: lotSizes[0],
          maxLotSizes: lotSizes[1],
          technologyIds,
          materialIds,
          certificationsIds,
          incoterms,
          countryCodes,
          machines,
        };
        trackUserEvent(TrackerEvents.FEED_QUERY_SENT, {
          query,
        });

        let projectsToSave = projects;
        if (projects.length !== projectsData.meta.totalItems)
          projectsToSave = Array(projectsData.meta.totalItems).fill(null);
        projectsToSave.splice(
          (pageNumber - 1) * pageSize,
          projectsData.items.length,
          ...projectsData.items,
        );
        setProjects(projectsToSave);

        dispatchFilters({
          type: 'SET_PAGINATION',
          payload: {
            ...pagination,
            totalItems: projectsData.meta.totalItems,
            totalPages: projectsData.meta.totalPages,
          },
        });

        setWasUserLoggedInAtLastFetch(isLoggedIn);
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pagination.currentPage,
      pagination.pageSize,
      certificationsIds,
      countryCodes,
      filterRanges.maxLotSizes,
      filterRanges.maxWorkpieces,
      filterRanges.minLotSizes,
      filterRanges.minWorkpieces,
      incoterms,
      isLoading,
      isLoadingFilters,
      isLoggedIn,
      lotSizes,
      showExpired,
      sort,
      technologyIds,
      materialIds,
      machines,
      wasUserLoggedInAtLastFetch,
      workpieces,
      viewMode,
    ],
  );

  const applyOverrideWithAI = React.useCallback(async () => {
    if (!currentUser) return;
    try {
      setIsLoading(true);
      const res = await getManufacturersByUrl({
        partfox_id: currentUser?.company?.id,
      });
      const { manufacturer } = res;

      const manMachines: SearchMachineDto[] = [];
      manufacturer.parts.forEach((part) => {
        part.machines.forEach((machine) => {
          manMachines.push({
            brand: machine.brand,
            model: machine.model,
          });
        });
      });
      if (
        localStorage.getItem('feed:showAddMachineHint') === 'false' &&
        manMachines.length === 0
      ) {
        setShowAddMachineHint(true);
      }
      const manTechnologies = manufacturer.technologyIds?.filter((t) =>
        searchableTechnologies.map((st) => st.id).includes(t),
      );
      const manMaterials = manufacturer.materialIds?.filter((m) =>
        searchableMaterials.map((sm) => sm.id).includes(m),
      );
      const manCertifications = manufacturer.certificationIds?.filter((c) =>
        searchableCertificates.map((sc) => sc.id).includes(c),
      );
      const manCountry = searchableCountries
        .map((sc) => sc.id)
        .includes(manufacturer.country)
        ? manufacturer.country
        : undefined;

      const showSparksForMachines = manMachines && manMachines.length > 0;
      const showSparksForMaterials = manMaterials && manMaterials.length > 0;
      const showSparksForTechnologies =
        manTechnologies && manTechnologies.length > 0;
      const showSparksForCertifications = (manCertifications &&
        manCertifications.length > 0) as boolean;
      const showSparksForCountryCodes = !!(
        manCountry && filterRanges.countryCodes.includes(manCountry)
      );
      const newFiltersToSort: FilterType[] = [];
      if (showSparksForMachines) newFiltersToSort.push(FilterType.machines);
      if (showSparksForMaterials) newFiltersToSort.push(FilterType.materials);
      if (showSparksForTechnologies)
        newFiltersToSort.push(FilterType.technologies);
      if (showSparksForCertifications)
        newFiltersToSort.push(FilterType.certifications);
      if (showSparksForCountryCodes)
        newFiltersToSort.push(FilterType.countryCodes);
      setFiltersToSort([...filtersToSort, ...newFiltersToSort]);

      dispatchFilters({
        type: 'SET_VALUES_WITH_AI',
        payload: {
          machines:
            manMachines && manMachines.length > 0
              ? manMachines
              : filters.machines,
          materialIds:
            manMaterials && manMaterials.length > 0
              ? manMaterials
              : filters.materialIds,
          technologyIds:
            manTechnologies && manTechnologies.length > 0
              ? manTechnologies
              : filters.technologyIds,
          certificationsIds:
            manCertifications && manCertifications.length > 0
              ? manCertifications
              : filters.certificationsIds,
          countryCodes:
            manCountry && filterRanges.countryCodes.includes(manCountry)
              ? [manCountry]
              : filters.countryCodes,
          showSparksForMachines,
          showSparksForMaterials,
          showSparksForTechnologies,
          showSparksForCertifications,
          showSparksForCountryCodes,
        },
      });
      trackUserEvent(TrackerEvents.FEED_AI_MATCHING_APPLIED);
    } catch (error) {
      errorHandler.capture(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    TrackerEvents.FEED_AI_MATCHING_APPLIED,
    currentUser,
    filterRanges.countryCodes,
    filters.certificationsIds,
    filters.countryCodes,
    filters.machines,
    filters.materialIds,
    filters.technologyIds,
    filtersToSort,
    trackUserEvent,
  ]);

  const clearAllFilters = React.useCallback(() => {
    dispatchFilters({
      type: 'RESET_FILTERS',
      payload: {
        workpieces: [filterRanges.minWorkpieces, filterRanges.maxWorkpieces],
        lotSizes: [filterRanges.minLotSizes, filterRanges.maxLotSizes],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterRanges.maxLotSizes,
    filterRanges.maxWorkpieces,
    filterRanges.minLotSizes,
    filterRanges.minWorkpieces,
  ]);

  const clearFiltersDisabled = React.useMemo(
    () =>
      machines.length === 0 &&
      materialIds.length === 0 &&
      technologyIds.length === 0 &&
      certificationsIds.length === 0 &&
      incoterms.length === 0 &&
      countryCodes.length === 0 &&
      workpieces[0] === filterRanges.minWorkpieces &&
      workpieces[1] === filterRanges.maxWorkpieces &&
      lotSizes[0] === filterRanges.minLotSizes &&
      lotSizes[1] === filterRanges.maxLotSizes,
    [
      certificationsIds.length,
      countryCodes.length,
      filterRanges.maxLotSizes,
      filterRanges.maxWorkpieces,
      filterRanges.minLotSizes,
      filterRanges.minWorkpieces,
      incoterms.length,
      lotSizes,
      machines.length,
      materialIds.length,
      technologyIds.length,
      workpieces,
    ],
  );

  const filtersCount = React.useMemo(() => {
    let count = 0;
    if (machines.length > 0) count += 1;
    if (technologyIds.length > 0) count += 1;
    if (materialIds.length > 0) count += 1;
    if (certificationsIds.length > 0) count += 1;
    if (countryCodes.length > 0) count += 1;
    if (incoterms.length > 0) count += 1;
    if (
      workpieces[0] !== filterRanges.minWorkpieces ||
      workpieces[1] !== filterRanges.maxWorkpieces
    )
      count += 1;
    if (
      lotSizes[0] !== filterRanges.minLotSizes ||
      lotSizes[1] !== filterRanges.maxLotSizes
    )
      count += 1;
    return count;
  }, [
    certificationsIds.length,
    countryCodes.length,
    filterRanges.maxLotSizes,
    filterRanges.maxWorkpieces,
    filterRanges.minLotSizes,
    filterRanges.minWorkpieces,
    incoterms.length,
    lotSizes,
    machines.length,
    materialIds.length,
    technologyIds.length,
    workpieces,
  ]);

  React.useEffect(() => {
    if (pagination.currentPage <= pagination.totalPages)
      getFeedProjects(pagination.currentPage, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, pagination.pageSize]);

  React.useEffect(() => {
    setProjects([]);
    if (pagination.currentPage === 1)
      getFeedProjects(pagination.currentPage, pagination.pageSize);
    else {
      dispatchFilters({
        type: 'SET_PAGINATION',
        payload: {
          ...pagination,
          currentPage: 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    isLoadingUserInfo,
    sort,
    showExpired,
    technologyIds,
    materialIds,
    certificationsIds,
    machines,
    incoterms,
    countryCodes,
    workpieces,
    lotSizes,
    viewMode,
  ]);

  React.useEffect(() => {
    const getFilterRanges = async () => {
      try {
        if (isLoadingFilters) return;
        setIsLoadingFilters(true);
        const ranges =
          await ProjectsService.projectsControllerGetFeedFilterRanges();
        setFilterRanges({
          countryCodes: ranges.countryCodes ?? [],
          minWorkpieces: ranges.minWorkpieces ?? 1,
          maxWorkpieces: ranges.maxWorkpieces ?? 1,
          minLotSizes: ranges.minLotSizes ?? 1,
          maxLotSizes: ranges.maxLotSizes ?? 1,
        });
        dispatchFilters({
          type: 'SET_WORKPIECES',
          payload: [ranges.minWorkpieces ?? 1, ranges.maxWorkpieces ?? 1],
        });
        dispatchFilters({
          type: 'SET_LOT_SIZES',
          payload: [ranges.minLotSizes ?? 1, ranges.maxLotSizes ?? 1],
        });
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
      } finally {
        setIsLoadingFilters(false);
        getFeedProjects(pagination.currentPage, pagination.pageSize);
      }
    };
    getFilterRanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({
      isLoading,
      viewMode,
      setViewMode,
      filterRanges,
      projects,
      pagination,
      sort,
      technologyIds,
      certificationsIds,
      incoterms,
      countryCodes,
      workpieces,
      lotSizes,
      clearAllFilters,
      clearFiltersDisabled,
      applyOverrideWithAI,
      filters,
      dispatchFilters,
      filtersCount,
      filtersToSort,
      setFiltersToSort,
      showAddMachineHint,
      setShowAddMachineHint,
      setProjects,
      selectedProjectIdContact,
      setSelectedProjectIdContact,
      selectedProjectNdaModal,
      setSelectedProjectNdaModal,
    }),
    [
      isLoading,
      viewMode,
      filterRanges,
      projects,
      pagination,
      sort,
      technologyIds,
      certificationsIds,
      incoterms,
      countryCodes,
      workpieces,
      lotSizes,
      clearAllFilters,
      clearFiltersDisabled,
      applyOverrideWithAI,
      filters,
      filtersCount,
      filtersToSort,
      showAddMachineHint,
      setProjects,
      selectedProjectIdContact,
      setSelectedProjectIdContact,
      selectedProjectNdaModal,
      setSelectedProjectNdaModal,
    ],
  );

  return (
    <FeedContext.Provider value={value}>
      {children || <Outlet />}
    </FeedContext.Provider>
  );
}
FeedContext.displayName = 'FeedContext';

export const useFeedContext = () => React.useContext(FeedContext);
