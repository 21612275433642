import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Key } from 'w3c-keys';
import { z } from 'zod';

import { PasswordInput } from '@/core/components/password-input';
import { Button } from '@/core/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/core/components/ui/form';
import { Input } from '@/core/components/ui/input';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { toast } from '@/core/libs/toast';
import { User } from '@/generated/api';

import { Icon } from './Icon/Icon';
import { IconType } from './Icon/IconType';

interface ISignInForm {
  email: string;
  password: string;
}

interface SignInProps {
  onSignIn(user: User | undefined): void;
  onForgotPasswordClick(): void;
  isLegacyLogin?: boolean;
}

export function SignInForm({
  onSignIn,
  onForgotPasswordClick,
  isLegacyLogin = false,
}: SignInProps): JSX.Element {
  const { $t } = useIntl();
  const { login, isLoading, getUserData } = useAuthenticationContext();

  const formSchema: z.ZodType<ISignInForm> = z.object({
    email: z
      .string()
      .min(1, { message: $t({ id: 'Form.fieldRequired' }) })
      .email({ message: $t({ id: 'Form.invalidEmail' }) }),
    password: z.string().min(1, { message: $t({ id: 'Form.fieldRequired' }) }),
  });

  const initialValues: ISignInForm = {
    email: '',
    password: '',
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ISignInForm): Promise<void> => {
    await login(values.email.toLowerCase(), values.password);
    const user = await getUserData();
    toast({
      appearance: 'success',
      message: $t({ id: 'Account.successfulLogin' }),
    });
    onSignIn(user);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <FormField
            control={form.control}
            name="email"
            render={({ field }): JSX.Element => (
              <FormItem>
                <FormLabel>{$t({ id: 'General.emailAddress' })}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }): JSX.Element => (
              <FormItem>
                <FormLabel>{$t({ id: 'General.password' })}</FormLabel>
                <FormControl>
                  <PasswordInput
                    {...field}
                    data-testid="buyer-sign-up-form__password-input"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="space-y-4">
          <p
            role="button"
            tabIndex={0}
            onKeyDown={(e): void => {
              if (e.key === Key.Enter) {
                onForgotPasswordClick();
              }
            }}
            className="mt-4 text-neutral-700 text-sm underline"
            onClick={onForgotPasswordClick}
          >
            {$t({ id: 'Account.forgotPassword' })}
          </p>
          <Button
            disabled={isLoading}
            color="secondary"
            type="submit"
            className="w-full font-semibold"
            size="lg"
          >
            {isLoading && (
              <Icon
                type={IconType.CIRCLE}
                className="mr-2 h-4 w-4 animate-spin"
                aria-hidden="true"
              />
            )}
            <span>{$t({ id: 'General.login' })}</span>
          </Button>
          {isLegacyLogin && (
            <p>
              {$t(
                { id: 'Account.signupForFree' },
                {
                  Action: (str: React.ReactNode) => (
                    <a
                      href="/registration"
                      className="text-primary-700 no-underline"
                    >
                      {str}
                    </a>
                  ),
                },
              )}
            </p>
          )}
        </div>
      </form>
    </Form>
  );
}
