import { useCallback, useRef, useState } from 'react';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { useSalesBoostUrl } from '@/core/hooks/useSalesBoostUrl';
import { errorHandler } from '@/core/libs/error-handler';
import TrackerEvents from '@/core/libs/event-tracker/TrackerEvents.enum';
import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import { MachineryFlagsEnum } from '@/modules/matchmaking/models/searchbar/enums/MachineryFlagsEnum';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import { MatchedManufacturer } from '@/modules/matchmaking/types';
import { getCountriesFromRegions } from '@/modules/matchmaking/utils';

import { useSearchTracking } from './useSearchTracking';

export type ManufacturerRecommendationRequest = {
  quantity?: number;
  technologies?: string[];
  materials?: string[];
  dimensionsMm?: (number | undefined)[];
  heightMm?: number;
  lengthMm?: number;
  widthMm?: number;
  diameterMm?: number;
  man_certifications?: string[];
  man_countries?: string[];
  man_employee_count_category?: string[];
  metrology_machines?: boolean;
  barFeeder?: boolean;
  man_has_capacity?: boolean;
  man_is_audited?: boolean;
  part_types?: string[];
};

export interface GetManufacturerRecommendationsPart {
  dimensionsMm?: [number, number, number];
  diameterMm?: number;
  quantity?: number;
  technologies?: string[];
  materials?: string[];
  surfaceTreatments?: string[];
  toleranceInMm?: string[];
  postProcessings?: string[];
  axesCount?: number;
  barFeeder?: boolean;
}

export type DetailedManufacturerRecommendationRequest = {
  parts: GetManufacturerRecommendationsPart[];
  man_certifications?: string[];
  man_countries?: string[];
  man_employee_count_category?: string[];
  metrology_machines?: boolean;
  man_has_capacity?: boolean;
  man_is_audited?: boolean;
  pageSize?: number;
  exclude?: string;
  excluded_mans_urls?: string[];
  excluded_mans_ids?: string[];
  triggered_by?: string;
};

export interface SearchResponse {
  success: boolean;
  total: number;
  matching: number;
  manufacturers: MatchedManufacturer[];
  suggestedManufacturers?: MatchedManufacturer[];
  pageSize?: number;
  matchingMachines?: boolean;
}

export interface UseSearchResults {
  results: MatchedManufacturer[];
  suggestedResults: MatchedManufacturer[];
  totalManufacturers: number;
  totalMatching: number;
  isMatchingMachines: boolean;
  pageSize: number;
  loading: boolean;
  fetch(query: SearchQuery, page?: number): Promise<void>;
}

export function searchQueryFormatter(
  query: SearchQuery,
): ManufacturerRecommendationRequest {
  let countryList: string[] | undefined;
  let dimensions: (number | undefined)[] | undefined;

  if (query.regions || query.countries) {
    countryList = [
      ...new Set([
        ...getCountriesFromRegions(query.regions),
        ...(query.countries ?? []),
      ]),
    ];
  }

  if (query.length || query.width || query.height) {
    dimensions = [query.length, query.width, query.height];
  }

  return {
    technologies: query.technologies,
    materials: query.materials,
    dimensionsMm: dimensions,
    heightMm: query.height,
    widthMm: query.width,
    lengthMm: query.length,
    diameterMm: query.diameter,
    man_certifications: query.certifications,
    man_countries: countryList,
    man_employee_count_category: query.companySize,
    part_types: query.part_types,
    metrology_machines: query.machinery?.some(
      (val) => val === MachineryFlagsEnum.METROLOGY_MACHINES,
    )
      ? true
      : undefined,
    barFeeder: query.machinery?.some(
      (val) => val === MachineryFlagsEnum.BAR_FEEDER,
    )
      ? true
      : undefined,
    man_is_audited: query.isVerified || undefined,
    man_has_capacity: query.hasCapacity || undefined,
  };
}

export function useSearchResults(): UseSearchResults {
  const { trackEventWithSearch } = useSearchTracking();
  const controller = useRef<AbortController>(new AbortController());
  const [results, setResults] = useState<MatchedManufacturer[]>([]);
  const [suggestedResults, setSuggestedResults] = useState<
    MatchedManufacturer[]
  >([]);
  const [totalManufacturers, setTotalManufacturers] = useState<number>(0);
  const [totalMatching, setTotalMatching] = useState<number>(0);
  const [isMatchingMachines, setIsMatchingMachines] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(50);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useAuthenticationContext();
  const searchBoostedUrl = useSalesBoostUrl();

  const fetch = useCallback(
    async (query: SearchQuery, page?: number): Promise<void> => {
      const oldController = controller.current;
      controller.current.abort();
      controller.current = new AbortController();
      setLoading(true);

      try {
        const response = await fetchApi.post<SearchResponse>(
          `${
            import.meta.env.PUBLIC_SEARCH_ENGINE_API_URL
          }/api/man-search/get-manufacturer-recommendations`,
          {
            ...searchQueryFormatter(query),
            force_boosted_man_url: searchBoostedUrl,
            page: page ? page - 1 : 0,
            triggered_by: currentUser?.company.id.toString(),
          },
          {
            signal: controller.current.signal,
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              'Content-Type': 'text/plain; charset=utf-8',
            },
          },
        );
        // This event should happen after await in case several searches are triggered quickly
        // and cancelled, only the successful one is the one we care
        trackEventWithSearch(TrackerEvents.SEARCH_QUERY, { query, page });
        const {
          manufacturers,
          suggestedManufacturers = [],
          total,
          matching,
          matchingMachines,
          pageSize: responsePageSize,
        } = response;
        setResults(manufacturers);
        setSuggestedResults(suggestedManufacturers);
        setTotalManufacturers(total);
        setTotalMatching(matching);
        setPageSize((value) => responsePageSize ?? value);
        setIsMatchingMachines(!!matchingMachines);
        setLoading(false);
      } catch (error) {
        if (!oldController.signal.aborted) {
          // In case it failed because other reason than controlled abort we track the search
          // so it doesn't get lost in the statistics
          trackEventWithSearch(TrackerEvents.SEARCH_QUERY, { query, page });
          errorHandler.capture(error, { avoidFlashMessage: true });
          setLoading(false);
        }
      }
    },
    [trackEventWithSearch, searchBoostedUrl, currentUser],
  );

  return {
    results,
    suggestedResults,
    loading,
    totalManufacturers,
    totalMatching,
    isMatchingMachines,
    pageSize,
    fetch,
  };
}
