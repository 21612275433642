import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import {
  DetailedManufacturerRecommendationRequest,
  GetManufacturerRecommendationsPart,
  SearchResponse,
} from '@/modules/matchmaking/hooks/useSearchResults';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import { AnalyzedPart, MatchedManufacturer } from '@/modules/matchmaking/types';

import { getCountriesFromRegions } from '../utils';

export interface GetManufacturerRecommendationsResponse {
  manufacturers: MatchedManufacturer[];
  suggestedManufacturers: MatchedManufacturer[];
  matching: number;
  total: number;
  loadingTimeMS: number;
}

function transformAnalyzedPartArrayIntoGetManufacturerRecommendationsPartArray(
  analyzedParts: AnalyzedPart[],
): GetManufacturerRecommendationsPart[] {
  return analyzedParts.map((part) => ({
    dimensionsMm:
      part.dimensionsMm && part.dimensionsMm.length > 0
        ? (part.dimensionsMm as [number, number, number])
        : undefined,
    diameterMm: part.turningDiameterMm,
    quantity: part.quantity,
    technologies: part.technologies.map((technology) => technology.id),
    materials: part.materials.map((material) => material.id),
    surfaceTreatments: part.surfaceTreatments,
  }));
}

export function getManufacturerRecommendations(
  analizedPartList: AnalyzedPart[],
  filters: SearchQuery,
  pageSize?: number,
  excludeUrl?: string,
  triggeredBy?: string,
  excludedMansUrls?: string[],
  excludedMansIds?: string[],
): Promise<GetManufacturerRecommendationsResponse> {
  const startTime = Date.now();

  return new Promise<GetManufacturerRecommendationsResponse>(
    (resolve, reject) => {
      async function getRecommendations(
        partList: AnalyzedPart[],
      ): Promise<void> {
        try {
          const parts =
            transformAnalyzedPartArrayIntoGetManufacturerRecommendationsPartArray(
              partList,
            );
          const countries = [
            ...new Set([
              ...getCountriesFromRegions(filters.regions),
              ...(filters.countries ?? []),
            ]),
          ];
          const request: DetailedManufacturerRecommendationRequest = {
            parts,
            man_certifications: filters.certifications?.length
              ? filters.certifications
              : undefined,
            man_countries: countries?.length ? countries : undefined,
            man_has_capacity: filters.hasCapacity ? true : undefined,
            man_is_audited: filters.isVerified ? true : undefined,
            pageSize: pageSize ?? undefined,
            exclude: excludeUrl,
            triggered_by: triggeredBy,
            excluded_mans_ids: excludedMansIds,
            excluded_mans_urls: excludedMansUrls,
          };
          const apiUrl = import.meta.env.PUBLIC_SEARCH_ENGINE_API_URL as string;
          const results = await fetchApi.post<SearchResponse>(
            `${apiUrl}/api/man-search/get-manufacturer-recommendations`,
            request,
            {
              cache: 'no-cache',
              credentials: 'include',
              headers: {
                'Content-Type': 'text/plain; charset=utf-8',
              },
            },
          );

          resolve({
            manufacturers: results.manufacturers,
            suggestedManufacturers: results.suggestedManufacturers ?? [],
            matching: results.matching,
            total: results.total,
            loadingTimeMS: Date.now() - startTime,
          });
        } catch (error) {
          reject(error);
        }
      }

      if (analizedPartList.length) {
        getRecommendations(analizedPartList);
      } else {
        reject(new Error('No parts to get recommendations'));
      }
    },
  );
}
