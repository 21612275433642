import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import {
  AnalyzedPart,
  WorkingPart,
  WorkingPartsMap,
} from '@/modules/matchmaking/types';

export interface AnalyzePartFilesResponse {
  parts: WorkingPartsMap;
  loadingTimeMS: number;
}

export function analyzePartFiles(
  workingParts: WorkingPartsMap,
): Promise<AnalyzePartFilesResponse> {
  const startTime = Date.now();
  const values = Object.values(workingParts);

  return new Promise<AnalyzePartFilesResponse>((resolve, reject) => {
    const apiUrl = import.meta.env.PUBLIC_ANALYSER_API_URL as string;
    const pullAnalyzedParts = async (
      partsToAnalyze: WorkingPart[],
    ): Promise<void> => {
      try {
        const results = await fetchApi.post<{ parts?: AnalyzedPart[] }>(
          `${apiUrl}/api/man-search/analyze-parts`,
          {
            parts: partsToAnalyze.map(({ files, batchSizes, id }) => ({
              id: id ? id : undefined,
              ...files,
              quantity: batchSizes[0],
            })),
          },
          {
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              'Content-Type': 'text/plain; charset=utf-8',
            },
          },
        );
        if (results.parts?.length) {
          const finalList = results.parts.reduce<WorkingPartsMap>(
            (list, analyzedPart, index) => {
              if (partsToAnalyze[index]) {
                const part = partsToAnalyze[index];
                return {
                  ...list,
                  [part.id]: {
                    ...part,
                    analyzedPart,
                  },
                };
              }
              return list;
            },
            {},
          );
          resolve({ parts: finalList, loadingTimeMS: Date.now() - startTime });
        } else {
          setTimeout(() => pullAnalyzedParts(partsToAnalyze), 2000);
        }
      } catch (error) {
        reject(error);
      }
    };

    if (values.length) {
      pullAnalyzedParts(values);
    } else {
      reject(new Error('No parts to analyze'));
    }
  });
}
