import { v4 } from 'uuid';

import { pdfThumbnail } from '@/core/libs/pdfThumbnail';
import staticIntl from '@/core/libs/static-intl';
import { toast } from '@/core/libs/toast';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { AnalyzedPartNamed, Workpiece } from '@/modules/matchmaking/types';
import { isTurningPart, sortDimensions } from '@/modules/matchmaking/utils';
import { SearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SearchableConfigurationFactory';
import { ProjectPart } from '@/generated/api';

const technologiesList: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.technologies,
  );

const materialsList: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.materials,
  );

const checkIfHasFileType = (
  workpiece: Workpiece,
  files: AnalyzedPartNamed[],
): boolean => {
  const fileTypes = files.map((file) => file.name.split('.').pop());
  return workpiece.files.some((file) =>
    fileTypes.includes(file.name.split('.').pop()),
  );
};

export function splitFilesToWorkpieces(
  files: AnalyzedPartNamed[],
  existingWorkpieces: Workpiece[] = [],
): Workpiece[] {
  const getFilename = (file: AnalyzedPartNamed): string =>
    file.name.split('.').slice(0, -1).join('.');

  const fileMap: Record<string, AnalyzedPartNamed[]> = {};
  files.forEach((file) => {
    const filename = getFilename(file);
    if (Object.prototype.hasOwnProperty.call(fileMap, filename)) {
      fileMap[filename] = [...fileMap[filename], file];
    } else {
      fileMap[filename] = [file];
    }
  });

  const newWorkpieces = [
    ...Object.entries(fileMap).map(([groupname, fileGroup]) => {
      const workpieceAlreadyExists = existingWorkpieces.findIndex((w) =>
        w.files.some((f) => getFilename(f) === groupname),
      );

      const technologies = fileGroup
        .map((file) => file.technologies)
        .flat()
        .filter((t) => technologiesList.some((tech) => tech.id === t.id));

      const materials = fileGroup
        .map((file) => file.materials)
        .flat()
        .filter((m) => materialsList.some((mat) => mat.id === m.id));

      const dimensionsMm = fileGroup
        .map((file) => file.dimensionsMm)
        .flat()
        .slice(0, 3);

      // Business logic: Longest dimension first for assigning to length
      const isTurning = isTurningPart(technologies.map((t) => t.id));
      const sortedDimensions = sortDimensions(dimensionsMm, isTurning, true);

      if (workpieceAlreadyExists !== -1) {
        const workpieceToUpdate = existingWorkpieces[workpieceAlreadyExists];
        if (checkIfHasFileType(workpieceToUpdate, fileGroup)) {
          toast({
            appearance: 'danger',
            message: staticIntl.instance.$t({
              id: 'CreateRequest.workpieces.errors.existingFile',
            }),
          });
          return;
        }
        existingWorkpieces[workpieceAlreadyExists] = {
          ...workpieceToUpdate,
          files: [...workpieceToUpdate.files, ...fileGroup],
          technologies,
          materials,
          dimensionsMm: sortedDimensions,
          showTechnologiesBadge: technologies.length > 0,
          showMaterialsBadge: materials.length > 0,
          showDimensionsBadge: dimensionsMm.length > 0,
        } satisfies Workpiece;
      } else {
        return {
          id: v4(),
          name: groupname,
          files: fileGroup,
          technologies,
          materials,
          dimensionsMm: sortedDimensions,
          quantities: [0],
          showTechnologiesBadge: technologies.length > 0,
          showMaterialsBadge: materials.length > 0,
          showDimensionsBadge: dimensionsMm.length > 0,
        } satisfies Workpiece;
      }
    }),
  ];

  return [...existingWorkpieces, ...newWorkpieces].filter(
    Boolean,
  ) as Workpiece[];
}

export function partToWorkpiece(part: ProjectPart): Workpiece {
  let stepFile: AnalyzedPartNamed | undefined = undefined;
  let pdfFile: AnalyzedPartNamed | undefined = undefined;

  const dimensionsMm: [number, number, number] | [] = part.length
    ? [part.length, part.width ?? 0, part.height ?? 0]
    : [];
  const technologies = (part.technologies ?? []).map((tech) => ({ id: tech }));
  const materials = part.materials.map((material) => ({
    id: material.material,
    designation: material.designation,
  }));

  const commonPart: Pick<
    AnalyzedPartNamed,
    | 'technologies'
    | 'materials'
    | 'dimensionsMm'
    | 'quantity'
    | 'tolerances'
    | 'surfaceTolerances'
    | 'surfaceTreatments'
    | 'volumeCm3'
    | 'turningDiameterMm'
  > = {
    technologies,
    materials,
    dimensionsMm,
    turningDiameterMm: part.diameter || undefined,
    tolerances: [],
    surfaceTolerances: [],
    surfaceTreatments: [],
    volumeCm3: 0,
    quantity: 0,
  };

  if (part.stepFile) {
    stepFile = {
      id: v4(),
      name: part.stepFile.name,
      stepFilename: part.stepFile.name,
      stepUrl: part.stepFile.url,
      glbUrl: part.glbUrl!,
      imageUrl: part.pngUrl!,
      ...commonPart,
    };
  }
  if (part.pdfFile) {
    pdfFile = {
      id: v4(),
      name: part.pdfFile.name,
      pdfFilename: part.pdfFile.name,
      pdfUrl: part.pdfFile.url,
      imageUrl: part.pngUrl!,
      pdfPreviewImg: pdfThumbnail(part.pdfFile.url, 'rfq'),
      ...commonPart,
    };
  }

  return {
    dimensionsMm,
    materials,
    technologies,
    id: v4(),
    showTechnologiesBadge: false,
    showDimensionsBadge: false,
    showMaterialsBadge: false,
    name: part.name!,
    quantities: part.quantities,
    files: [stepFile, pdfFile].filter(Boolean) as AnalyzedPartNamed[],
  };
}
