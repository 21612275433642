import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@/core/components/ui/button';
import { IconButton } from '@/core/components/ui/icon-button';
import { Separator } from '@/core/components/ui/separator';
import { Sheet, SheetContent, SheetHeader } from '@/core/components/ui/sheet';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { Breakpoints, useBreakpoint } from '@/core/context/BreakpointContext';
import { LanguageCode, useLocaleContext } from '@/core/context/I18nContext';
import { User } from '@/generated/api';

import { Icon } from '../component-library/Icon/Icon';
import { Badge } from '../ui/badge';
import FlagDE from './icons/FlagDE';
import FlagEN from './icons/FlagEN';
import FlagES from './icons/FlagES';
import FlagIT from './icons/FlagIT';
import FlagTR from './icons/FlagTR';
import FlagZH from './icons/FlagZH';
import { NavItem } from './nav-item';
import { NavMenu } from './nav-menu';
import { NavTitle } from './nav-title';
import OrderfoxLogo from './PartfoxLogo';
import { TopNavItem } from './top-nav-item';

export interface TopnavProps {
  expanded: boolean;
  onExpanded(expanded: boolean): void;
}

export function TopNav({
  expanded,
  onExpanded: setExpanded,
}: TopnavProps): JSX.Element {
  const { $t } = useIntl();
  const { setUserLocale } = useLocaleContext();
  const { breakpoint } = useBreakpoint();
  const location = useLocation();
  const { isLoggedIn, currentUser, openLoginModal, logout } =
    useAuthenticationContext();

  const collapseMenu = useCallback(() => setExpanded(false), [setExpanded]);

  useEffect(() => {
    collapseMenu();
  }, [location, collapseMenu]);

  return (
    <>
      <header className="fixed top-3 left-4 p-4 right-4 min-h-[64px] bg-white rounded-xl shadow-topBar z-20 lg:hidden">
        <nav className="flex items-center justify-between">
          <div>
            <OrderfoxLogo
              className="w-auto h-8"
              showBrandName={breakpoint > Breakpoints.SM}
            />
          </div>
          <div>
            <IconButton
              appearance="subtle"
              aria-label="Expand"
              onClick={(): void => setExpanded(true)}
              className="flex items-center justify-center"
            >
              <Icon
                type={Icon.type.BURGER_MENU}
                className="h-8 w-8 text-[28px]"
              />
            </IconButton>
          </div>
        </nav>
      </header>
      <Sheet open={expanded} onOpenChange={(e) => setExpanded(e)}>
        <SheetContent className="my-3 rounded-xl px-0" showCloseButton={false}>
          <SheetHeader className="flex items-center justify-between flex-row px-8">
            <OrderfoxLogo
              className="w-auto h-8"
              showBrandName
              white={!expanded}
            />
            <div>
              <IconButton
                appearance="subtle"
                aria-label="Expand"
                onClick={(): void => setExpanded(false)}
                className="flex items-center justify-center"
              >
                <Icon
                  type={Icon.type.X_CROSS}
                  className="h-8 w-8 text-[28px]"
                />
              </IconButton>
            </div>
          </SheetHeader>
          <nav className="py-8 h-[calc(100%-2rem)] flex flex-col overflow-y-auto px-4">
            <NavTitle name={$t({ id: 'menu.searchAndExplore' })} />
            <TopNavItem
              to="/search"
              active={location.pathname.includes('/search')}
            >
              <Icon
                type={Icon.type.ZOOM}
                className="w-6 h-6 text-xl flex items-center justify-center"
              />
              {$t({ id: 'supplierSearch.menu.search' })}
            </TopNavItem>

            {currentUser?.type !== User.type.BUYER && (
              <TopNavItem
                as={Link}
                to="/feed"
                active={location.pathname.includes('/feed')}
              >
                <Icon
                  type={Icon.type.COPY}
                  className="w-6 h-6 text-xl flex items-center justify-center"
                />
                <>
                  {$t({ id: 'menu.feed' })}
                  <Badge
                    size="xxs"
                    color="primary"
                    className="cursor-pointer uppercase"
                  >
                    {$t({ id: 'General.new' })}
                  </Badge>
                </>
              </TopNavItem>
            )}

            <NavTitle name={$t({ id: 'menu.buyerCockpit' })} />
            <TopNavItem
              as={isLoggedIn ? Link : Button}
              to="/projects"
              onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
              active={
                location.pathname.includes('projects') ||
                location.pathname.includes('project')
              }
            >
              <Icon
                type={Icon.type.DOC_FOLDER}
                className="w-6 h-6 text-xl flex items-center justify-center"
              />
              {$t({ id: 'projectOverview.pageTitle' })}
            </TopNavItem>
            <TopNavItem
              as={isLoggedIn ? Link : Button}
              to="/my-manufacturer-lists"
              onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
              active={location.pathname.includes('my-manufacturer-lists')}
            >
              <Icon
                type={Icon.type.BOOKMARK}
                className="w-6 h-6 text-xl flex items-center justify-center"
              />
              {$t({ id: 'MyManufacturers.title' })}
            </TopNavItem>
            {currentUser?.type !== User.type.BUYER && (
              <>
                <NavTitle name={$t({ id: 'menu.manufacturerCockpit' })} />

                <TopNavItem
                  as={isLoggedIn ? Link : Button}
                  to="/requests"
                  onClick={
                    isLoggedIn ? undefined : (): void => openLoginModal()
                  }
                  active={
                    location.pathname.includes('/requests') ||
                    location.pathname.includes('/request')
                  }
                >
                  <Icon
                    type={Icon.type.SELECTED_REQUESTS}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'requestOverview.pageTitle' })}
                </TopNavItem>
                <TopNavItem
                  as={isLoggedIn ? Link : Button}
                  to="/manufacturer-profile"
                  onClick={
                    isLoggedIn ? undefined : (): void => openLoginModal()
                  }
                  active={
                    location.pathname.includes('manufacturer-profile') &&
                    !location.pathname.includes('my-machinery') &&
                    !location.pathname.includes('my-sample-parts')
                  }
                >
                  <Icon
                    type={Icon.type.BUSINESS_CARD}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'General.yourVisibility' })}
                </TopNavItem>
                <TopNavItem
                  as={isLoggedIn ? Link : Button}
                  to="/manufacturer-profile/my-machinery"
                  onClick={
                    isLoggedIn ? undefined : (): void => openLoginModal()
                  }
                  active={location.pathname.includes(
                    'manufacturer-profile/my-machinery',
                  )}
                >
                  <Icon
                    type={Icon.type.CNC_MACHINE}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'Machinery.myMachinery' })}
                </TopNavItem>
                <TopNavItem
                  as={isLoggedIn ? Link : Button}
                  to="/manufacturer-profile/my-sample-parts"
                  onClick={
                    isLoggedIn ? undefined : (): void => openLoginModal()
                  }
                  active={location.pathname.includes(
                    '/manufacturer-profile/my-sample-parts',
                  )}
                >
                  <Icon
                    type={Icon.type.BOLT}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'SampleParts.mySampleParts' })}
                </TopNavItem>
              </>
            )}

            <div className="mt-auto mb-8">
              <Separator className="my-7" />
              {isLoggedIn && currentUser?.role === User.role._0 && (
                <TopNavItem
                  as={isLoggedIn ? Link : Button}
                  to="/admin/ghost-mode"
                  onClick={
                    isLoggedIn ? undefined : (): void => openLoginModal()
                  }
                  active={location.pathname.includes('ghost-mode')}
                >
                  <Icon
                    type={Icon.type.GHOST_PACMAN}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'GhostMode.title' })}
                </TopNavItem>
              )}
              {!isLoggedIn && (
                <NavMenu
                  title={$t({ id: 'General.language' })}
                  icon={Icon.type.TRANSLATION}
                >
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.ENGLISH)}
                    icon={<FlagEN />}
                    text="English"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.GERMAN)}
                    icon={<FlagDE />}
                    text="Deutsch"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.ITALIAN)}
                    icon={<FlagIT />}
                    text="Italiano"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.SPANISH)}
                    icon={<FlagES />}
                    text="Español"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.TURKISH)}
                    icon={<FlagTR />}
                    text="Türkçe"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                  <NavItem
                    onClick={(): void => setUserLocale(LanguageCode.CHINESE)}
                    icon={<FlagZH />}
                    text="中文"
                    showTooltip={false}
                    className="h-10"
                    isSubMenuItem
                  />
                </NavMenu>
              )}
              <TopNavItem
                as={isLoggedIn ? Link : Button}
                to="/settings"
                onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
                active={location.pathname.includes('settings')}
              >
                <Icon
                  type={Icon.type.SETTINGS}
                  className="w-6 h-6 text-xl flex items-center justify-center"
                />
                {$t({ id: 'General.settings' })}
              </TopNavItem>
              {isLoggedIn ? (
                <TopNavItem
                  onClick={(): void => {
                    collapseMenu();
                    logout();
                  }}
                >
                  <Icon
                    type={Icon.type.LOGOUT}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'General.logout' })}
                </TopNavItem>
              ) : (
                <TopNavItem
                  onClick={(): void =>
                    openLoginModal({
                      onLogin: collapseMenu,
                      onSignup: collapseMenu,
                    })
                  }
                >
                  <Icon
                    type={Icon.type.LOGIN}
                    className="w-6 h-6 text-xl flex items-center justify-center"
                  />
                  {$t({ id: 'General.login' })}
                </TopNavItem>
              )}
            </div>
          </nav>
        </SheetContent>
      </Sheet>
    </>
  );
}
