import { Link } from 'react-router-dom';

import { cn } from '@/core/libs/utils';

export function TopNavItem({
  as = Link,
  to,
  active,
  children,
  onClick,
}: {
  as?: React.ElementType;
  to?: string;
  active?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}): JSX.Element {
  const Component = as;
  return (
    <Component
      to={to}
      onClick={onClick}
      className={cn(
        'w-full transition-colors bg-transparent flex items-center gap-4 no-underline py-4 px-4 justify-start text-base h-14 hover:bg-neutral-100 rounded-xl hover:no-underline hover:text-neutral-600 focus-visible:no-underline focus-visible:text-neutral-600 font-normal',
        active ? 'text-primary-700' : 'text-neutral-600',
      )}
    >
      {children}
    </Component>
  );
}
