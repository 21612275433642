import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { IconType } from '@/core/components/component-library/Icon/IconType';
import { Button } from '@/core/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/core/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/core/components/ui/form';
import { Input } from '@/core/components/ui/input';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import { cn } from '@/core/libs/utils';

export interface ResetPasswordForm {
  email: string;
}

export interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose(): void;
  className?: string;
}

export function ResetPasswordModal({
  isOpen,
  onClose,
  className,
}: ResetPasswordModalProps): JSX.Element {
  const { $t } = useIntl();
  const { resetPasswordForEmail, isLoading } = useAuthenticationContext();

  const [hasResetRequestBeenSent, setHasResetRequestBeenSent] =
    useState<boolean>(false);

  const formSchema: z.ZodType<ResetPasswordForm> = z.object({
    email: z
      .string()
      .min(1, { message: $t({ id: 'Form.fieldRequired' }) })
      .email({ message: $t({ id: 'Form.invalidEmail' }) }),
  });

  const initialValues: ResetPasswordForm = {
    email: '',
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: ResetPasswordForm): Promise<void> => {
    try {
      await resetPasswordForEmail(values.email);
      setHasResetRequestBeenSent(true);
    } catch (error) {
      errorHandler.capture(error, { avoidFlashMessage: true });
    }
  };

  const handleOnClose = (): void => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setHasResetRequestBeenSent(false);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOnClose}>
      <DialogContent className={cn('max-w-md', className)}>
        <DialogHeader>
          <DialogTitle>{$t({ id: 'Account.resetPassword' })}</DialogTitle>
        </DialogHeader>
        {!hasResetRequestBeenSent ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="email"
                render={({ field }): JSX.Element => (
                  <FormItem>
                    <FormLabel>{$t({ id: 'General.emailAddress' })}</FormLabel>
                    <FormControl>
                      <Input {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                disabled={isLoading}
                color="primary"
                type="submit"
                className="w-full mt-4"
                size="lg"
              >
                {(form.formState.isSubmitting || isLoading) && (
                  <Icon
                    type={IconType.CIRCLE}
                    className="mr-2 h-4 w-4 animate-spin"
                    aria-hidden="true"
                  />
                )}
                <span>{$t({ id: 'General.submit' })}</span>
              </Button>
            </form>
          </Form>
        ) : (
          <p className="text-neutral-700">
            {$t({ id: 'Account.resetPwdCheckMail' })}
          </p>
        )}
        <DialogFooter>
          {hasResetRequestBeenSent && (
            <Button
              onClick={handleOnClose}
              color="neutral"
              className="w-full"
              size="lg"
            >
              {$t({ id: 'General.close' })}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
